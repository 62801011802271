import Vue from 'vue'
import VueRouter from 'vue-router'

// import Login from '../views/Login.vue'
// import Blogs from '../views/Blogs.vue'
// import Index from '../views/Index.vue'
// import BlogDetail from '../views/BlogDetail.vue'
// import BlogEdit from '../views/BlogEdit.vue'
// import Category from '../views/Category.vue'
// import Tag from '../views/Tag.vue'
// import Link from '../views/Link.vue'
// import About from '../views/About.vue'

// import Manage from '../views/manage/Manage.vue'
// import MLogin from '../views/manage/Login.vue'
// import Dashboard from '../views/manage/Dashboard.vue'
// import MyData from '../views/manage/MyData.vue'
// import Article from '../views/manage/Article.vue'
// import CategoryAndTag from '../views/manage/CategoryAndTag.vue'

// import Error404 from '../components/Error404.vue'


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Shouye',
    redirect:{name:"Index"}   // 访问主页的时候重定向到blogs页面
  } , 
  {
    path: '/blogs',
    name: 'Blogs',
    // component: Blogs
    component: resolve=>(require(["../views/Blogs.vue"],resolve)),   // 路由懒加载
    meta:{keepAlive: true }
  } , 
  {
    path: '/index',
    name: 'Index',
    // component: Index
    component: resolve=>(require(["../views/Index.vue"],resolve)) , 
    meta:{keepAlive: true }
  } , 
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // } , 
  // {
  //   path: '/blog/add',
  //   name: 'BlogAdd',    // name不能重复 但是可以跳到同一个组件
  //   component: BlogEdit , 
  //   meta:{requireAuth:true}   // 路由拦截，表示该路由对应的页面要登录之后才能访问
  // } ,
  {
    path: '/blog/:blogId',
    name: 'BlogDetail',
    // component: BlogDetail
    component: resolve=>(require(["../views/BlogDetail.vue"],resolve)) , 
    meta:{keepAlive: false }
  } , 
  // {
  //   path: '/blog/:blogId/edit',
  //   name: 'BlogEdit',
  //   component: BlogEdit, 
  //   meta:{requireAuth:true,}
  // } , 
  // {
  //   path: '/category/:typeId',
  //   name: 'Category',   
  //   component: Category , 
  // } , 
  {
    path: '/category/:typeName',
    name: 'Category',   
    // component: Category , 
    component: resolve=>(require(["../views/Category.vue"],resolve)) , 
    meta:{keepAlive: true }
  } , 
  {
    path: '/tag/:tagName',
    name: 'Tag',   
    // component: Tag , 
    component: resolve=>(require(["../views/Tag.vue"],resolve)) , 
    meta:{keepAlive: false }
  } , 
  {
    path: '/link',
    name: 'Link',   
    // component: Link , 
    component: resolve=>(require(["../views/Link.vue"],resolve)) , 
    meta:{keepAlive: true }
  } , 
  {
    path: '/message',
    name: 'Message',   
    component: resolve=>(require(["../views/Message.vue"],resolve)) , 
    meta:{keepAlive: true }
  } , 
  {
    path: '/about',
    name: 'About',   
    // component: About , 
    component: resolve=>(require(["../views/About.vue"],resolve)) , 
    meta:{keepAlive: true }
  } , 
  {
    path: '/ishui',
    name: 'Music',   
    // component: About , 
    component: resolve=>(require(["../views/others/Music.vue"],resolve)) , 
    meta:{keepAlive: true }
  } , 
  {
    path: '/more1',
    name: 'TreeHole',   
    // component: About , 
    component: resolve=>(require(["../views/others/TreeHole.vue"],resolve)) , 
    meta:{keepAlive: true }
  } , 
  {
    path: '/more2',
    name: 'Music',   
    // component: About , 
    component: resolve=>(require(["../views/others/Music.vue"],resolve)) , 
    meta:{keepAlive: true }
  } , 
  // 后台管理
  {
    path: '/manage',
    name: 'Manage',   
    // component: Manage , 
    component: resolve=>(require(["../views/manage/Manage.vue"],resolve)) ,
    redirect : "/manage/dashboard" ,  // 访问 /manage 重定向到 /manage/dashboard
    children : [
      {path:"/manage/dashboard" , component: resolve=>(require(["../views/manage/Dashboard.vue"],resolve))} , 
      {path:"/manage/myData" , component: resolve=>(require(["../views/manage/MyData.vue"],resolve))} , 
      {path:"/manage/articles" , component: resolve=>(require(["../views/manage/Article.vue"],resolve))} , 
      {path:"/manage/categoryAndTag" , component: resolve=>(require(["../views/manage/CategoryAndTag.vue"],resolve))} , 
      {path:"/manage/friendLinks" , component: resolve=>(require(["../views/manage/FriendLinks.vue"],resolve))} , 
      {path:"/manage/messageAndComment" , component: resolve=>(require(["../views/manage/MessageAndComment.vue"],resolve))} , 
      {path:"/manage/visitorInfo" , component: resolve=>(require(["../views/manage/VisitorInfo.vue"],resolve))} , 
    ]
  } ,
  { 
    path: '/manage/login',
    name: 'MLogin',   
    // component: MLogin , 
    component: resolve=>(require(["../views/manage/Login.vue"],resolve))
  } , 
  // 404
  {
    path:"/404" , 
    // component: Error404 , 
    component: resolve=>(require(["../components/Error404.vue"],resolve)) ,
    name:"404" , 
    meta:{
      keepAlive:true , 
      isTab:false , 
      isAuth:false
    }
  } , 
  {
    path:"*" , 
    redirect:"/404"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



// // 挂载路由导航守卫（全局前置守卫）
// router.beforeEach((to , from , next) => {
//   // to 将要访问
//   // from 从哪儿访问
//   // next 接着做什么  next(url) 重定向到url上 next() 继续访问to的路径
//   // if(to.path == '/manage') return next() ; // 如果在首页上，放行
//   // 获取user
//   // console.log(window.sessionStorage.getItem("userInfo") ) ;
//   const userFlag =  window.sessionStorage.getItem("userInfo") ;  // 取出当前用户
//   if( !userFlag ) {
//     return next('/manage/login') ;// 无值返回到登录页面
//   }  
//   // else if(to.path === '/user' || to.path === '/sport'){
//   //   return next("/home") ; 
//   // }
//   else{
//     return next() ; // 符合要求  放行
//   }

// })


export default router
