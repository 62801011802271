import axios from 'axios'
import Element from 'element-ui'
import store from './store'
import router from './router'
import { showFullScreenLoading, tryHideFullScreenLoading } from './assets/loading';

// 全局前置拦截和后置拦截

// 设置请求前缀
axios.defaults.baseURL = "https://linwuaiwo.cn:9999";
// axios.defaults.baseURL = "http://localhost:9999";

// 前置拦截
axios.interceptors.request.use(config => {
  if(config.showLoading){
    showFullScreenLoading() ;
  }
  return config;
})


// 后置拦截，拦截请求，当返回数据不符合我们期待的值的时候，返回错误提示，否则再继续放行
axios.interceptors.response.use(response => {
  if (response.config.showLoading) {
    tryHideFullScreenLoading() ; 
  }
  let res = response.data;
  // console.log("============拦截到的请求===============");
  // console.log(res);
  // console.log("===========================");

  if (res.code === 200) {  // 如果状态码是200 则放行
    return response;
  } else {  // 不是200则给出提示
    Element.Message.error("密码错误~", { duration: 1.5 * 1000 });
    return Promise.reject(response.data.msg);   // 这里是获取到请求中的错误提示，返回给控制台
  }
}, error => {   // 当是其他状态码（不是200和400），返回相应的提示
  if (error.response.data) { // 获取错误信息
    error.message = error.response.data.msg ; 
  }

  if (error.response.status == 401) {
    store.commit("REMOVE_INFO");
    router.push("/index");
  }else{
    Element.Message.error(error.message, { duration: 1 * 1000 });
    return Promise.reject(error);  
  }
})