<template>

  <!-- <div id="app" v-loading.fullscreen.lock="fullscreenLoading"> -->
    <!-- <keep-alive> -->
      <!-- <router-view/> -->
    <!-- </keep-alive> -->
  <!-- </div> -->
  <div id="app">
    <!-- 返回上一页面，页面停留在原来位置，不刷新 -->
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 正常情况 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>


<script>
// 刷新页面后回到顶部
window.addEventListener('beforeunload',e=>{
  window.scroll(0,0)
})
export default {
  name: 'App',
  data () {
    return {
      fullscreenLoading: false  , 
      transitionName:'' , 
    }
  },
  methods:{
    // 控制台打印
    consoleLog(){
      // let date = new Date().format("yyyy-MM-dd hh:mm");
      let word = "愿有岁月可回首，且以深情迎余生。" ; 
      let xiaowu = 'background: pink; color: #fff; border-radius: 3px 0 0 3px; font-size:14px;line-height: 22px;'
      let shijian = 'background: rgb(82,191,213); color: #fff; border-radius: 0 3px 3px 0; font-size:14px;line-height: 22px;'
      console.log(`%c 小wu的天空❤ %c ${word} `, xiaowu, shijian) ; 

      let res = 
      `
   _ _                           _               
  | (_)                         (_)              
  | |_ _ ____      ___   _  __ _ ___      _____  
  | | | '_ \\ \\ /\\ / / | | |/ _\` | \\ \\ /\\ / / _ \\ 
  | | | | | \\ V  V /| |_| | (_| | |\\ V  V / (_) |
  |_|_|_| |_|\\_/\\_/  \\__,_|\\__,_|_| \\_/\\_/ \\___/ 
                                                                                                  
      ` ; 
      let linwuaiwo = ' color: rgb(82,191,213); '
      console.log(`%c ${res}` , linwuaiwo  ) ; 
    } , 
    openFullScreen1() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 1000);
     },
    openFullScreen2() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
      }, 1000);
    }
  } , 
  watch: {

  } , 
  created(){
    // this.openFullScreen2() ; 
    this.consoleLog() ; 

  }
}
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  font-family:  "Play","Microsoft Yahei","Open Sans",sans-serif;
}
/* 七彩背景 */
/* 这个七彩背景可以搞成动态的，后面有时间做一下 */
/* body{
  background-image: radial-gradient(rgba(192, 82, 255, 0.3),rgba(255,0,0,0) 40vw),radial-gradient(rgba(0,128,0,0.3),rgba(0,128,0,0) 40vw),radial-gradient(rgba(0,0,255,0.3),rgba(0,0,255,0) 40vw),radial-gradient(rgba(0, 239, 255, 0.3),rgba(255,255,0,0) 40vw),radial-gradient(rgba(0, 215, 255, 0.3),rgba(255,0,0,0) 40vw);
  background-position: -40vw 14rem,50% 10rem,60vw 14rem,-10vw calc(14rem + 20vw),30vw calc(14rem + 20vw);
  background-repeat: no-repeat;
} */

body {
    background-position: top center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(https://linwu.oss-cn-shanghai.aliyuncs.com/bgs/bg.jpg);
  }


a{
	text-decoration: none;
}

/* 文字选中背景色 */
::selection {
    background:rgb(82,191,213);
    color:rgb(250, 250, 250);
}

/* 改变滚动条默认样式 */
html::-webkit-scrollbar{
  width: .5rem;
  height: .01rem;
}
html::-webkit-scrollbar-thumb{
  border-radius: 1rem;
  box-shadow: inset 0 0 .3rem rgba(0, 0, 0, .2);
  background-color: rgb(82,191,213);
}
html::-webkit-scrollbar-track{
  border-radius: 1rem;
  box-shadow: inset 0 0 .3rem rgba(0, 0, 0, .2);
  background-color: #ededed;
}





</style>
