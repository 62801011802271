import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    // 设置全局属性
    token:'' ,    // 这里我们也可以用 相同的方式获取token  localStorage.getItem("token") ; 
    userInfo:JSON.parse(sessionStorage.getItem("userInfo"))  // 将下面设置会话信息获取到，并反序列化成对象
  },
  mutations: {
    // set方法
    SET_TOKEN: (state , token) =>{
      state.token = token ; 
      // 将token保存到浏览器 的localStorage中
      localStorage.setItem("token" , token) ; 
    },
    SET_USERINFO: (state , userInfo) =>{
      state.userInfo = userInfo ; 
      // 将用户信息保存到会话中
      sessionStorage.setItem("userInfo" , JSON.stringify(userInfo)) ;   // session中不能存对象，所以我们将userInfo序列化
    } , 
    REMOVE_INFO: (state) =>{    // 清除数据
      state.token = '' ; 
      state.userInfo = {} ; 
      localStorage.setItem("token" , '') ; 
      sessionStorage.setItem("userInfo" , JSON.stringify('')) ; 
    } , 
    

  },
  actions: {
    // get方法
    // 我们可以使用get的方式获取userInfo 或者直接像state中那样写也可以，直接在sessionStorage中获取
    getUser: state => {
      return state.userInfo ; 
    }

  },
  modules: {
  }
})
